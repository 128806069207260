import TopBar from "../components/TopBar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function About() {
    return (
        <div>
            <TopBar />
            <div id="about">
                <p>Charles Ingea is an interdisciplinary designer working at the intersection of computation and the moving image.</p>
                <p>His work explores the potential of code in enabling new forms of knowledge representation.</p>
                <p>Originally from Beirut, he is currently based in London where he works as an Experience Designer at Arup.</p>
            </div>
            <br />
            <div id="contact">
                <p>For any enquiries:</p>
                <ul>
                    <li><FontAwesomeIcon icon={faEnvelope} />charlesingea [at] gmail [dot] com</li>
                    <li><a href="https://www.linkedin.com/in/charles-ingea/" target="_blank"><FontAwesomeIcon icon={faLinkedin} />linkedin.com/in/charles-ingea</a></li>
                </ul>
            </div>
        </div>
    )
}


export default About;