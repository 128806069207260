import TopBar from "../components/TopBar";
import video1 from "../assets/beirut-postcards-1.mp4"
import photo1 from "../assets/beirut-postcards-2.png"
import photo2 from "../assets/beirut-postcards-3.png"
import video2 from "../assets/beirut-postcards-4.mp4"
import video3 from "../assets/beirut-postcards-5.mp4"

function Postcards() {
    return(
        <div>
            <div><TopBar /></div>
            <div className="project-text">
                <h1 className="project-title">Beirut Postcards</h1>
                <p className="project-description">Beirut Postcards is an augmented reality experience in which
                postcards of pre-war Beirut are visualised in space at the location from which their photographs were taken.</p>
            </div>
            <div className="project-content">
                <div>
                    <p>In the wake of the Beirut Port explosion, and as Beirut was rebuilding itself yet another
                time since the civil war, I turned to my mother's extensive collection of pre-war postcards—visions of a bygone
                city that she collected to illustrate her own father's written account of pre-war Beirut.
                Adding my own contribution to this generational project, I augmented the postcards by virtually placing them in
                the city, in an attempt to memorialise Beirut's past as the city gets rebuilt again.</p>
                </div>
                <video playsInline autoPlay loop muted>
                    <source src={video1} />
                </video>
                <div>
                    <p>I digitised 65 postcards of Beirut's Martyrs' Square, dated between 1920 and 1972. Using a photogrammetry algorithm, and taking the historic Rivoli building as a reference, I placed 26 of the 65 postcards in 3D space relative to each other based on their location in the city.</p>
                </div>
                <img src={photo1} alt="A screen capture of a photogrammetry software showing postcards in 3D space." />
                <div>
                    <p>Using the current location where the Rivoli building used to stand, I localised the postcards in real-world coordinates and rendered them in an augmented reality mobile app. Each postcard is visualised at the location and angle from which its photograph was originally captured.</p>
                </div>
                <img src={photo2} alt="A screen capture of a 3D modelling software showing postcards in low-fidelity model of Beirut." />
            </div>
            <div className="project-gallery">
                <video playsInline autoPlay loop muted>
                    <source src={video2} />
                </video>
                <video playsInline autoPlay loop muted>
                    <source src={video3} />
                </video>
            </div>
        </div>
    )
}

export default Postcards;