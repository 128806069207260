import { Link } from 'react-router-dom';
import cv from "../assets/CV.pdf"

function TopBar() {
    return (
        <header className="header">
            <div className="title">
                <Link to="/"><h1>Charles Ingea</h1></Link>
            </div>
            <nav className="links">
                <Link to="/about"><h2>about</h2></Link>
                <Link to="/"><h2>work</h2></Link>
                <a href={cv} target='_blank'><h2>CV</h2></a>
            </nav>
        </header>
    )
}

export default TopBar;