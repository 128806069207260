import TopBar from "../components/TopBar";
import photo1 from "../assets/polaroids-1.jpg"

function Polaroids() {
    return(
        <div>
            <div><TopBar /></div>
            <div className="project-text">
                <h1 className="project-title">Souvenirs from the metaverse<br/>(In progress)</h1>
                <p className="project-description"><em>Souvenirs from the metaverse</em> is an in-progress experiment
                aimed at repurposing analog media to capture tangible memories of time spent in virtual places.</p>
            </div>
            <div className="project-content">
                <div>
                    <p>The first experiment in this project is an instant camera that captures and prints tangible photographs
                        of virtual scenes. Using a Vive tracker and a wireless photo printer,
                        I am retrofitting a Polaroid camera to make it VR-compatible and allow it to render and print shots from virtual reality scenes instantly.
                    </p>
                </div>
                <img src={photo1} alt="A 3D rendering of a Polaroid camera equipped with a virtual reality tracker."/>
            </div>
        </div>
    )
}

export default Polaroids;