import TopBar from "../components/TopBar";
import Project from "../components/Project";
import revolution2019 from "../assets/Revolution-2019.mp4";
import beirutPostcards from "../assets/Postcards.mp4";
import experienceLab from "../assets/experiencelab-4.png";
import cadavreExquis from "../assets/cadavre-exquis-2.mp4"
import aperture from "../assets/aperture-1.jpg"
import polaroids from "../assets/polaroids-1.jpg"
import darc from "../assets/darc-1.jpg"


const projects = [
    {
        title: "Revolution 2019",
        description: "An interactive documentary exploring the role of the Web in the emergence of a transnational culture in the 2019 protest wave.",
        image: revolution2019,
        isVideo: true,
        path: "/revolution-2019"
    },
    {
        title: "Cadavre Exquis",
        description: "A collaborative animation based on the surrealists' concept of the Cadavre Exquis.",
        image: cadavreExquis,
        isVideo: true,
        path: "/cadavre-exquis"
    },
    {
        title: "Arup ExperienceLab",
        description: "Arup's immersive multisensory experiential visualisation facility.",
        image: experienceLab,
        isVideo: false,
        path: "/arup-experiencelab"
    },
    {
        title: "Beirut Postcards",
        description: "Postcards of pre-war Beirut in augmented reality.",
        image: beirutPostcards,
        isVideo: true,
        path: "/beirut-postcards"
    },
    {
        title: "Arup Aperture",
        description: "An augmented reality photo exhibition for Arup.",
        image: aperture,
        isVideo: false,
        path: "/arup-aperture"
    },
    {
        title: "Souvenirs from the metaverse (In progress)",
        description: "Readapting analog media to capture digital memories.",
        image: polaroids,
        isVideo: false,
        path: "/souvenirs-from-the-metaverse"
    },
    {
        title: "[d]arc awards 2024 (In progress)",
        description: "A lighting installation for the 2024 [d]arc awards ceremony.",
        image: darc,
        isVideo: false,
        path: "/darc-awards"
    }
];


function RenderProjects() {
    var renderedProjects = [];
    projects.forEach(project => {
        renderedProjects.push(
        <Project
        title={project.title}
        description={project.description}
        image={project.image}
        isVideo={project.isVideo}
        path={project.path}
        className={project.className ? project.className : ""}/>
        );
    })
    return renderedProjects;
}


function Index() {
    return (
        <div>
            <TopBar />
            {RenderProjects()}
        </div>
    )
}


export default Index;