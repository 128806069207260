import { Link } from 'react-router-dom';

function RenderImage(isVideo, image) {
    if (isVideo) {
        return <video className="project-video" playsInline autoPlay loop muted><source src={image} /></video>
    }
    else {
        return <div className="project-image" style={{backgroundImage: `url(${image})`}}></div>
    }
}

function Project({title, description, image, isVideo, path, className}) {
    return (
        <div className={"project " + className}>
            <Link to={path}>
                {RenderImage(isVideo, image)}
                <h1>{title}</h1>
                <p>{description}</p>
            </Link>
        </div>
    )
}

export default Project