import TopBar from "../components/TopBar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import photo1 from "../assets/experiencelab-1.jpg"
import photo2 from "../assets/experiencelab-2.png"
import photo3 from "../assets/experiencelab-3.jpg"

function ArupExperienceLab() {
    return(
        <div>
            <div><TopBar /></div>
            <div className="project-text">
                <h1 className="project-title">Arup ExperienceLab</h1>
                <ul className="project-links">
                    <li><a href="https://www.arup.com/news-and-events/arup-brings-design-to-life-with-industry-first-immersive-facility" target="_blank" rel="noreferrer">Web <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a></li>
                </ul>
                <p className="project-description">As an Experience Designer at Arup, I have been involved in the
                development and launch of ExperienceLab, Arup's immersive multisensory simulation facility informing design
                approaches and outcomes.</p>
            </div>
            <div className="project-content">
                <div>
                    <p>ExperienceLab is Arup's experiential visualisation facility aimed at simulating the built environment
                        in a verified, calibrated way.
                        As an Experience Designer, I design tools and experiences combining captured experiential data with digital visualisations and auralisations.</p>
                </div>
                <img src={photo1} alt="A group of people equipped with 3D glasses looking at a simulation of a concert hall in Arup ExperienceLab."/>
                <div>
                    <p>I helped develop a reality capture device and workflow, combining stereoscopic video, ambisonic sound
                    recording, and land survey data, to create calibrated, verifiable representations of the built environment at a 1:1 scale.</p>
                </div>
                <img src={photo2} alt="A diagrammatic representation of a reality capture worflow, mapping information from a stereoscopic camera to a set of projectors."/>
                <div>
                    <p>I designed a real-time, interactive playback system, utilising motion tracking technology alongside multisensory data capture
                    to create interactive, immersive simulations of the built environment.</p>
                </div>
                <img src={photo3} alt=""/>
            </div>
        </div>
    )
}

export default ArupExperienceLab;