import TopBar from "../components/TopBar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import video1 from "../assets/Revolution-2019-3.mp4"
import video2 from "../assets/Revolution-2019-4.mp4"
import video3 from "../assets/Revolution-2019-5.mp4"

function Revolution2019() {
    return(
        <div>
            <div><TopBar /></div>
            <div className="project-text">
                <h1 className="project-title">Revolution 2019</h1>
                <ul className="project-links">
                    <li><a href="https://revolution-2019.vercel.app/" target="_blank" rel="noreferrer">Web <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a></li>
                </ul>
                <p className="project-description">Revolution 2019 is a Web-based interactive documentary designed as part of my undergraduate dissertation project. The documentary is a curated selection of Web artefacts investigating the role of social media in the emergence of a transnational culture shared across the 2019 protest wave. Blog posts, tweets, YouTube videos and GitHub repositories serve as building blocks in this investigation.</p>
            </div>
            <div className="project-content">
                <div>
                    <p>The year 2019 was marked by an unprecedented wave of anti-government protests sparking around the globe. As popular movements erupted in my hometown Beirut in October 2019, I was struck by the content flooding my social media feeds: protesters around the globe were sharing tactics, expressing their solidarity to each other, and bonding over similar demands. The Web had enabled the rise of a transnational conversation spanning many protest movements.</p>
                </div>
                <video playsInline autoPlay loop muted>
                    <source src={video1} />
                </video>
                <div>
                    <p>I approached the story in the form of an interactive documentary. Using the Web as a medium, I leveraged its possibilites for interactivity and non-linearity—allowing the story to emerge from the polyphonic juxtaposition of many elements.</p>
                </div>
                <video playsInline autoPlay loop muted>
                    <source src={video2} />
                </video>
                <div>
                    <p>The documentary uses Web artefacts as source material: tweets, YouTube videos, blog posts, and news articles are among the elements that were embedded to serve as building blocks to the story. This introduced ephemerality to the documentary: since its creation in 2019, it has slowly been degrading as elements are deleted and pulled from the Web.</p>
                </div>
                <video playsInline autoPlay loop muted>
                    <source src={video3} />
                </video>
            </div>
        </div>
    )
}

export default Revolution2019;