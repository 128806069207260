import TopBar from "../components/TopBar";
import photo1 from "../assets/aperture-2.jpg"
import photo2 from "../assets/aperture-3.jpg"
import video1 from "../assets/aperture-3.mp4"

function ArupAperture() {
    return(
        <div>
            <div><TopBar /></div>
            <div className="project-text">
                <h1 className="project-title">Arup Aperture</h1>
                <p className="project-description">Aperture is an augmented reality photo exhibition I designed and developed for Arup's
                2023 Annual General Meeting. Portraits of designers and engineers across the firm are exhibited on a wall, and
                come to life when a user scans them with a mobile app.</p>
            </div>
            <div className="project-content">
                <div>
                    <p>Aperture was designed to showcase people at the forefront of certain projects. By scanning
                        a portrait with a mobile app, visitors reveal information about a project and are invited to interact with it.
                    </p>
                </div>
                <img src={photo1} alt="A group of people scanning portraits with tablets to view them in augmented reality."/>
                <div>
                    <p>The only form of interaction with the content is through distance: moving closer or further away
                        from a portrait reveals more or less information about the project. Portraits act like portals within
                        the wall, revealing layers of information and inviting users to explore them spatially.
                    </p>
                </div>
                <video playsInline autoPlay loop muted>
                    <source src={video1} />
                </video>
                <div>
                    <p>In 2024, Aperture evolved into a mobile exhibition and embarked on a global tour of Arup offices, from Hong Kong to Melbourne to New York.
                    </p>
                </div>
                <img src={photo2} alt="Four photographs showcasing Arup Aperture in different office settings."/>
            </div>
        </div>
    )
}

export default ArupAperture;