import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Index from './pages/Index';
import About from './pages/About';
import Revolution2019 from './pages/Revolution2019';
import CadavreExquis from './pages/CadavreExquis';
import Postcards from './pages/Postcards';
import ArupExperienceLab from './pages/ArupExperienceLab';
import ArupAperture from './pages/ArupAperture';
import Polaroids from './pages/Polaroids';
import Darc from './pages/DarcAwards';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about" element={<About />} />
        <Route path="/revolution-2019" element={<Revolution2019 />} />
        <Route path="/cadavre-exquis" element={<CadavreExquis />} />
        <Route path="/arup-experiencelab" element={<ArupExperienceLab />} />
        <Route path="/beirut-postcards" element={<Postcards />} />
        <Route path="/arup-aperture" element={<ArupAperture />} />
        <Route path="/souvenirs-from-the-metaverse" element={<Polaroids />} />
        <Route path="/darc-awards" element={<Darc />} />
      </Routes>
    </Router>
  );
}

export default App;
