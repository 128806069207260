import TopBar from "../components/TopBar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import photo1 from "../assets/cadavre-exquis-1.png"
import video1 from "../assets/cadavre-exquis-3.mp4"

function CadavreExquis() {
    return(
        <div>
            <div><TopBar /></div>
            <div className="project-text">
                <h1 className="project-title">Cadavre Exquis</h1>
                <ul className="project-links">
                    <li><a href="https://draw-cadavre-exquis.vercel.app" target="_blank">Web <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a></li>
                </ul>
                <p className="project-description">Cadavre Exquis is a Web-based collaborative animation where each frame is drawn
                by a different person. It is based on the <em>Cadavre Exquis</em> ("Exquisite Corpse"), a game invented by the surrealists in which artists
                would take turns making contributions to a drawing without seeing the full picture.</p>
            </div>
            <div className="project-content">
                <div>
                    <p> Visitors to the website are shown the latest three frames of the animation, and are asked
                to come up with the next one. It is only once they submit their drawing that they are presented with the full animation. The surrealists' concept of the <em>Cadavre Exquis</em> is applied
                    here to the motion picture.</p>
                </div>
                <img src={photo1} alt="A screenshot of a web browser showing Cadavre Exquis."/>
                <div>
                    <p>I drew the initial three frames myself, taking inspiration from Émile Cohl's <em>Fantasmagorie</em> (1908), one of the earliest animated films.
                    I then shared the website link online for friends and strangers to submit their contributions.</p>
                    <p>This is the film as of December 2023:</p>
                </div>
                <video playsInline autoPlay loop muted>
                    <source src={video1} />
                </video>
            </div>
        </div>
    )
}

export default CadavreExquis;