import TopBar from "../components/TopBar";
import photo1 from "../assets/darc-1.jpg"

function Darc() {
    return(
        <div>
            <div><TopBar /></div>
            <div className="project-text">
                <h1 className="project-title">[d]arc awards 2024<br/>(In progress)</h1>
                <p className="project-description">The [d]arc awards is an annual competition celebrating lighting design in the UK. For the 2024 awards ceremony, lighting designers have been paired with manufacturers to design installations responding to the theme: the 1980s.</p>
            </div>
            <div className="project-content">
                <div>
                    <p>I was paired with lighting designer Kingfisher Lighting. Together we designed an interactive installation celebrating horror films from the 1980s: visitors enter in a conversation with a TV set, as the lighting around them responds to changes on the screen. Installations are limited to a 2m³ box.
                    </p>
                </div>
                <img src={photo1} alt="A 3D rendering of an installation replicating the interior of a 1980s living room. There is a red bean bag and a TV set."/>
            </div>
        </div>
    )
}

export default Darc;